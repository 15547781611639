import { createI18n } from "vue-i18n"
// lang
import ar_AE from './lang/ar_AE'
import en_US from './lang/en_US'
import es_ES from './lang/es_ES'
import fr_FR from './lang/fr_FR'
import hi_IN from './lang/hi_IN'
import ja_JP from './lang/ja_JP'
import ko_KR from './lang/ko_KR'
import pt_PT from './lang/pt_PT'
import ru_RU from './lang/ru_RU'
import tr_TR from './lang/tr_TR'
import vi_VN from './lang/vi_VN'
import zh_HK from './lang/zh_HK'

const i18n = createI18n({
  legacy: false,
  locale: 'en_US',
  messages: {
    'ar_AE': {
      ...ar_AE
    },
    'en_US': {
      ...en_US
    },
    'es_ES': {
      ...es_ES
    },
    'fr_FR': {
      ...fr_FR
    },
    'hi_IN': {
      ...hi_IN
    },
    'ja_JP': {
      ...ja_JP
    },
    'ko_KR': {
      ...ko_KR
    },
    'pt_PT': {
      ...pt_PT
    },
    'ru_RU': {
      ...ru_RU
    },
    'tr_TR': {
      ...tr_TR
    },
    'vi_VN': {
      ...vi_VN
    },
    'zh_HK': {
      ...zh_HK
    }
  }
})

export default i18n