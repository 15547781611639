import { createApp } from 'vue'
import App from './App.vue'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'

import router from './router/index'
import vConsole from 'vconsole'
// 用户获取唯一标识
import fingerprintjs from './utils/fingerprintjsUtil'
import appBridge from './utils/appBridge'
import domainHelper from './utils/domainHelper'
import '@/assets/css/reset.css'
// 皮肤
import '@/assets/css/skin.css'
import i18n from './i18n/index'
import i18nHelper from './utils/i18nHelper'

const app = createApp(App)
app.use(domainHelper)
app.use(i18n)
app.use(router)
app.use(i18nHelper)
if (process.env.NODE_ENV != 'production') {
  app.use(new vConsole())
}
app.use(fingerprintjs)
app.use(appBridge)

app.mount('#app')
