import { getAppPackage } from './app'
import Cookies from "js-cookie"

const appBridge = {
  install(app, options) {
    Cookies.set('app-package', getAppPackage())
  },
  getAppPackage() {
    return Cookies.get('app-package') || ''
  }
}

export default appBridge