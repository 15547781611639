import {
  createRouter,
  createWebHistory
} from 'vue-router'

import domainHelper from '@/utils/domainHelper'
const routes =
  [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/captcha',
      name: 'captcha',
      component: () => import('@/views/captcha/Captcha.vue')
    },
    {
      path: '/invitation',
      name: 'invitation',
      component: () => import('@/views/user/Invitation.vue')
    },
    {
      path: '/registration-success',
      name: 'registrationSuccess',
      component: () => import('@/views/user/RegistrationSuccess.vue')
    },
    {
      path: '/help-center',
      name: 'helpCenter',
      component: () => import('@/views/user/HelpCenter.vue')
    },
    {
      path: '/otp-help',
      name: 'OTPHelp',
      component: () => import('@/views/other/OTPHelp.vue')
    },
    {
      path: '/removeUser',
      name: 'deleteUser',
      component: () => domainHelper.get().deleteUserComponent
    },
    {
      path: '/babyConnect/invitation',
      name: 'babyConnectInvitation',
      component: () => import('@/views/user/babeConnect/Invitation.vue')
    },
    {
      path: '/privacyagreement',
      name: 'privacyPolicy',
      component: domainHelper.get().privateAgreementComponent
    },
    {
      path: '/termsservice',
      name: 'termsOfService',
      component: domainHelper.get().termsOfServiceComponent
    },
    {
      path: '/stripe/pay-success',
      name: 'stripePaySuccess',
      component: () => import('@/views/payment/stripe/Success.vue')
    },
    {
      path: '/auth/google/callback',
      name: 'googleAuthCallback',
      component: () => import('@/views/auth/google/Callback.vue')
    },
    {
      path: '/client/grade/detail',
      name: 'clientGradeDetail',
      component: () => import('@/views/user/ClientGradeDetail.vue')
    }
  ]

const router = new createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  routes
})


export default router
