const domainConfigs = [
  {
    regular: /\.joviain\.com$/,
    title: 'Jovia',
    favicon: `${process.env.BASE_URL}jovia/favicon.ico`,
    privateAgreementComponent: () => import('@/views/user/jovia/PrivacyPolicy.vue'),
    termsOfServiceComponent: () => import('@/views/user/jovia/TermsOfService.vue'),
    deleteUserComponent: () => import('@/views/user/jovia/DeleteUser.vue'),
    importMethod: () => {
      import('@/assets/css/jovia/skin.css')
    }
  },
  {
    regular: /\.chimeup65\.com$/,
    title: 'ChimeUp',
    favicon: `${process.env.BASE_URL}chimeUp/favicon.ico`,
    privateAgreementComponent: () => import('@/views/user/chimeUp/PrivacyPolicy.vue'),
    termsOfServiceComponent: () => import('@/views/user/chimeUp/TermsOfService.vue'),
    deleteUserComponent: () => import('@/views/user/chimeUp/DeleteUser.vue'),
    importMethod: () => {
      import('@/assets/css/chimeUp/skin.css')
    }
  },
  {
    regular: /\.joymd\.top$/,
    title: 'BabeConnect',
    favicon: `${process.env.BASE_URL}babeConnect/favicon.ico`,
    privateAgreementComponent: () => import('@/views/user/babeConnect/PrivacyPolicy.vue'),
    termsOfServiceComponent: () => import('@/views/user/babeConnect/TermsOfService.vue'),
    deleteUserComponent: () => import('@/views/user/babeConnect/DeleteUser.vue'),
    importMethod: () => {
      import('@/assets/css/skin.css')
    }
  },
  {
    regular: /^.*$/,
    title: 'unknown',
    favicon: `${process.env.BASE_URL}favicon.ico`,
    privateAgreementComponent: () => import('@/views/user/babeConnect/PrivacyPolicy.vue'),
    termsOfServiceComponent: () => import('@/views/user/babeConnect/TermsOfService.vue'),
    deleteUserComponent: () => import('@/views/user/babeConnect/DeleteUser.vue'),
    importMethod: () => {
      import('@/assets/css/defaultSkin.css')
    }
  }
]

export default domainConfigs