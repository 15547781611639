import domainConfigs from '@/config/domainConfig.js'

const domainHelper = {
  install(app, option) {
    const config = this.get()
    config.importMethod()
    window.document.title = config.title
    const link = window.document.querySelector("link[ref*='icon']") || window.document.createElement("link")
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = config.favicon
    window.document.getElementsByTagName("head")[0].appendChild(link)
  },
  match(origin) {
    let result
    for(const config of domainConfigs) {
      if (config.regular.test(origin)) {
        result = config
        break
      }
    }
    return result
  },
  get() {
    const origin = window.location.origin
    return this.match(origin)
  }
}

export default domainHelper