import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Cookies from "js-cookie"


const visitorIdKey = 'visitorId'
const fingerprintjs = {
  install(app, options) {
    FingerprintJS.load()
    .then(fp => fp.get())
    .then(result => {
      Cookies.set(visitorIdKey, result.visitorId)
    })
  },
  getVisitorId() {
    return Cookies.get(visitorIdKey) || `@${Math.random().toString(36).substr(2)}`
  }
}

export default fingerprintjs