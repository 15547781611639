import i18n from '../i18n/index'
import { useI18n } from 'vue-i18n'

const adaptorMap = {
  'hi': 'hi_IN',
  'en': 'en_US',
  'pt': 'pt_PT',
  'tr': 'tr_TR',
  'ru': 'ru_RU',
  'fr': 'fr_FR',
  'es': 'es_ES',
  'ja': 'ja_JP',
  'ko': 'ko_KR',
  'vi': 'vi_VN',
  'zh': 'zh_HK',
  'zh-rTW': 'zh_HK',
  'zh-rHK': 'zh_HK',
  'ar': 'ar_AE'
}
// const { locale } = useI18n()
// console.log(locale)
const i18nHelper = {
  install(app, option) {
  },
  setLanguage(language) {
    const aLanguage = adaptorMap[language] || 'en_US'
    const { locale } = useI18n()
    locale.value = aLanguage
  }
}

export default i18nHelper